<template>
<section id="viewer" class="flex-row flex-align-start flex-justify-start pa-05 border round-05 overflow">
  <div
    v-for="file in files"
    :key="file.fileID"
    class="file pa-05 round-05 relative"
    :class="{ 'selectable' : select, 'selected': file.fileID == selectedFileID }"
    style="max-width: 150px; overflow-wrap: break-word;"
    @click.stop="selectFile( file )"
  >
    <div >
      <img v-if="file.mimeType.startsWith('image')" :src="`${baseURL}/SiteAdmin/FilesAPI/getAttachment/${file.fileID}/${hostID}/${hostType}`" style="width: 100%; max-width: 150px;" />
      <div v-else class="border round-05" style="width: calc( 150px - 1em ); height: 150px;"></div>
      <span v-if="!file.mimeType.startsWith('image')" class="absolute pa-05 font-size-1-5" style="left: 8px; top: 8px;" :class="file.mimeIconClass" />
      <span v-if="!select" id="deleteIcon" class="absolute icon-bin2 pa-05" style="right: 8px; top: 8px;" @click="deleteFile( file )" />
    </div>
    <div v-if="select">{{ file.fileName }}</div>
    <a v-else :href="`/SiteAdmin/FilesAPI/getFile/${file.fileID}`" target="_blank">{{ file.fileName }}</a>
  </div>
  <div v-if="listError" class="warning">Error getting file list</div>
  <div v-else-if="files.length == 0" class="w-100 color-gray-60 center pa-1">Uploaded files will appear here</div>
</section>
</template>



<script>
import FilesAPI from '@/api/FilesAPI.js'
import File from './File.js'
import { base_url } from '@/Config.js'

export default {
	name: 'AttachmentFileViewer.vue',


	props: {
		hostType: {
			type: String,
			default: null
		},

		hostID: {
			type: Number,
			default: null
		},

    select: {
      type: Boolean,
      default: false
    },

    selectedFileID: {
      type: Number,
      default: null
    },
	},


  model: {
		prop: "selectedFileID",
		event: "select",
	},



  data() {
    return {
      files: [],
      listError: false,
    }
  },


  created() { this.initialize() },


  computed: {
    baseURL() { return base_url },
  },


  watch: {
    hostType() { this.initialize() },
    hostID() { this.initialize() },
  },


  methods: {

    async initialize() {
      this.listError = false
      const data = await FilesAPI.getAttachmentList( this.hostID, this.hostType )
      .catch( err => {
        this.listError = true
        this.files = []
      })
      this.files = []
      for( const f of data ) this.files.push( File.import( f ) )
    },


    selectFile( file ) {
      if( this.selectedFileID && file.fileID === this.selectedFileID ) this.$emit( 'select', null )
      else this.$emit( 'select', file.fileID )
    },


    async deleteFile( file ) {

      const yes = confirm( `Really delete file '${file.fileName}'?` )
      if( !yes ) return

      FilesAPI.removeFile( file.fileID, this.hostID, this.hostType )
      .then( () => this.initialize() )
      .catch( () => this.initialize() )
    }
  }
}
</script>



<style scoped>
#viewer {
  background-color: white;
}

.file.selectable { cursor: pointer; }
.file.selectable:hover {
  background-color: var(--pp10-gray-90);
}

.file.selected {
  border: 1px solid var(--ekno-blue);
  background: var(--ekno-blue-90);
}

#deleteIcon { display: none; }
.file:hover #deleteIcon { display: inherit; }
#deleteIcon:hover {
  color: var(--pp10-red);
}
</style>