<style scoped>
.attachment {
	margin: 0.15em 0.5em;
	padding: 0.5em;
	background: #bcf;
	color: var(--ekno-blue);
	/* border: 1px solid #ddd; */
	border-radius: 0.5em;
	display: inline-block;
}
.attachment:first-of-type { margin-left: 0em; }
.attachment:last-of-type { margin-right: 0em; }

.icon { margin-right: 0.5em; }
.delete { margin-left: 0.75em; }
.delete:hover { color: red }
</style>


<template>
<section>
	<!-- <div v-if="!attachments.length" class="mb-1"><i>Attachments will appear here</i></div>
	<div v-else class="mb-1">
		<span class="attachment" v-for="attachment of attachments" :key="attachment.fileID" @mouseenter="showTip($event)" @mouseleave="hideTip" :tooltip="attachment.fileName">
			<span class="icon" :class="attachment.mimeIconClass" />
			<a :href="getAttachmentURL(attachment)" :download="attachment.fileName">{{attachment.shortname}}</a>
			<span class="icon-cross delete" @click.stop="deleteAttachment(attachment)" />
		</span>
	</div>

	<div v-if="!expand" @click="showUpload = !showUpload" class="link">attach file</div>
	<file-pond v-if="showUpload" :files="uploadFiles" @processfile="fileUploaded" allow-multiple="false" instantUpload="true" :server="filepondConfig" /> -->

	<AttachmentFileViewer :hostType="hostType" :hostID="hostID" :select="false" ref="fileViewer" />
	<AttachmentFileUploader :hostType="hostType" :hostID="hostID" @upload="$refs.fileViewer.initialize()" />

</section>
</template>


<script>
// import 'filepond/dist/filepond.min.css'
// import FilesAPI from '@/api/FilesAPI.js'
// import {base_url} from "@/Config.js";
// import FileAttachment from '@/features/FileAttachments/FileAttachment.js';
// import Tooltips from '@/libraries/Tooltips/Tooltips.js'
import AttachmentFileViewer from '../FileUploader/AttachmentFileViewer.vue'
import AttachmentFileUploader from '../FileUploader/AttachmentFileUploader.vue'

export default {
	name: 'FileAttachments',


	components: {
		AttachmentFileViewer,
		AttachmentFileUploader,
	},


	props: {
		hostType: String, // 'order' | 'customer' | 'customerOrg' | 'customerInteraction'
		hostID: Number,
		expand: {
			type: Boolean,
			default: false
		},
	},


	// data() {
	// 	return {
	// 		showUpload: false,
	// 		attachments: [],
	// 		uploadFiles: [],

	// 		filepondConfig: {
	// 			process: {
	// 				url: `${base_url}/SiteAdmin/FilesAPI/uploadFile/${this.hostID}/${this.hostType}`,
	// 				withCredentials: true,
	// 				method: 'POST',
	// 			}
	// 		},


	// 	}
	// },


	created() { this.initialize() },


	methods: {
		async initialize() {
			// this.attachments = []
			// this.showUpload = this.expand

			// // Download attachment list
			// const data = await FilesAPI.getAttachmentList(this.hostID, this.hostType)
			// for(let item of data) {

			// 	this.attachments.push(FileAttachment.import(item))
			// }
		},


		// fileUploaded() {
		// 	this.initialize()
		// },


		// downloadFile(attachment) {
		// 	FilesAPI.getAttachment(attachment.fileID, this.hostID, this.hostType)
		// },


		// async deleteAttachment(attachment) {
		// 	var yes = confirm(`Are you sure you want to delete ${attachment.fileName}?`)
		// 	if(!yes) return

		// 	await FilesAPI.removeFile(attachment.fileID, this.hostID, this.hostType)
		// 	this.initialize()
		// },


		// getAttachmentURL(attachment) {
		// 	return `${base_url}/SiteAdmin/FilesAPI/getAttachment/${attachment.fileID}/${this.hostID}/${this.hostType}`
		// },


		// showTip(ev){
		// 	return Tooltips.showTooltip(ev, "bottom")
		// },
		// hideTip(ev){
		// 	return Tooltips.hideTooltip(ev)
		// },


	},



}
</script>
