import { render, staticRenderFns } from "./AttachmentFileUploader.vue?vue&type=template&id=5dc5f122&scoped=true"
import script from "./AttachmentFileUploader.vue?vue&type=script&lang=js"
export * from "./AttachmentFileUploader.vue?vue&type=script&lang=js"
import style0 from "./AttachmentFileUploader.vue?vue&type=style&index=0&id=5dc5f122&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dc5f122",
  null
  
)

export default component.exports