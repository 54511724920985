<template>
<div>
	
	<section class="Tab sticky-top">
		<button @click="mode = 'general'" :class="{ active: mode === 'general' }">General</button>
		<button @click="mode = 'stats'" :class="{ active: mode === 'stats' }">Scores &amp; Stats</button>

		<section class="flex-row flex-justify-center flex-align-center flex-gap-05 ml-1" v-if="mode === 'stats'">
			<div class="tabLink" @click="statsMode = 'overview'" :class="{ active: statsMode === 'overview' }">Overview</div>
			<div class="tabLink" @click="statsMode = 'time'" :class="{ active: statsMode === 'time' }">Time</div>
			<div class="tabLink" @click="statsMode = 'lessons'" :class="{ active: statsMode === 'lessons' }">Lessons</div>
			<div class="tabLink" @click="statsMode = 'questions'" :class="{ active: statsMode === 'questions' }">Questions</div>
			<div class="tabLink" @click="statsMode = 'videos'" :class="{ active: statsMode === 'videos' }">Videos</div>
			<div class="tabLink" @click="statsMode = 'plan'" :class="{ active: statsMode === 'plan' }">Plan &amp; Schedule</div>
			<div class="tabLink" @click="statsMode = 'target'" :class="{ active: statsMode === 'target' }">Target Score</div>
		</section>
	</section>

	<section v-if='showSpinner' class='flex justify-center'>
		<div class='icon-spinner4 spin-loader'></div>
	</section>

	<section v-else-if="mode == 'general'" class="ma-1">
		<div class="flex-row flex-justify-between flex-align-start mb-1">
			<div class="flex-column flex-gap-1 flex-justify-between">

				<!-- Serial Key -->
				<label>Serial Key: 
					<span v-if="Mode === 'edit'" class="bold font-size-1-5">{{ newLicense.serialKey }}</span>
					<input v-else type="text" v-model="newLicense.serialKey" style="width: 20rem" />
					<span v-if="Mode === 'new'" class="link" @click="generateKey">Generate Key</span>
				</label>

				<!-- Dates -->
				<div class="flex-row flex-gap-1">
					<label>
						<div>Purchase Date:</div>
						<input type="date" @input="updateDate('pur', $event)" :value="momentToString(newLicense.purchaseDate)" disabled /><br />
					</label>

					<label>
						<div>Expiration Date:</div>
						<input type="date" @input="updateDate('exp', $event)" :value="momentToString(newLicense.expirationDate)" />
						<span v-if='!newLicense.expirationDate' class='mx-1'>No expiration</span>
					</label>
				</div>

				<!-- Checkboxes -->
				<div>
					<label>
						<input type="checkbox" :disabled="Mode !== 'new'" v-model='newLicense.teacherChatEnabled' />
						Teacher Chat
					</label><br>
					<label>
						<input type="checkbox" v-model="newLicense.trackingOn" />
						Tracking
					</label>
				</div>

			</div>


			<div class="flex-column flex-gap-1 flex-justify-between">
				<!-- Customer -->
				<label>
					Customer:
					<CustomerSearchDropdown v-model="customer" width="20rem" />
				</label>

				<!-- Affiliate -->
				<div>
					Affiliate:
					<AffiliateSearchDropdown v-model="affiliate" width="20rem" />
				</div>

				<!-- Master -->
				<label>
					<div class="flex-row flex-gap-1" style="margin-bottom: 5px">
						Master:

						<!-- Email Button -->
						<span v-if='Mode !== "new" && newLicense.masterID' @click='resendRegistrationEmail(newLicense.masterID)' class='link' style="font-size: 0.9em">
							Send "New License" Email
						</span>

					</div>
					<UserSearchDropdown
						:initialValue="displayMaster"
						width="25em"
						@updateUser="user => selectMaster(user)"
					/>
					<div v-if='master' class='font-secondary'>{{ master.numLicenses }} current licenses | <span class="link" @click.stop="openMaster()">user details &gt;</span></div>

				</label>

				<!-- Student -->
				<label>
					<div class="flex-row flex-gap-1" style="margin-bottom: 5px">
						Student:

						<!-- Email Button -->
						<span v-if='Mode !== "new" && newLicense.studentID' @click='resendRegistrationEmail(newLicense.studentID)' class='link' style="font-size: 0.9em">
							Send "New License" Email
						</span>
					</div>
					<UserSearchDropdown
						:initialValue="displayStudent"
						width="25em"
						@updateUser="user => selectStudent(user)"
					/>
					<div v-if='student' class='font-secondary'>{{ student.numLicenses }} current licenses | <span class="link" @click.stop="openStudent()">user details &gt;</span></div>
				</label>

				<!-- Reports Settings Button -->
				<span v-if='Mode != "new"' @click="showPushReportSettings = true" class="link" style="font-size: 0.9em">
					Push Report settings
				</span>
				<!-- Reports Settings Popup -->
				<LicenseReportSettings :show="showPushReportSettings" :masterUser="master" :licenseKey="newLicense.serialKey" @close="showPushReportSettings = false" />
			</div>
		</div>

		<label>
			<div>Class Info:</div>
			<div class="flex-row" style="flex-wrap: wrap">
				<div v-for="cohort in License.teacherCohortLinks.cohortsArray" :key="cohort.cohortID" class="class-info-block">
					<div style="margin-bottom: 5px"><strong>Class:</strong> {{ cohort.cohortName }} ({{ cohort.cohortID }})</div>
					<div v-if="cohort.teachers.size > 0">
						<strong>Teachers:</strong>
						<div v-for="(teacher, index) in Array.from(cohort.teachers.values())" :key="teacher.userID">
							{{ teacher.name }} ({{ teacher.userID }})<span v-if="index < cohort.teachers.size - 1">, </span>
						</div>
					</div>
				</div>

				<div v-for="teacher in License.teacherCohortLinks.getNonClassTeachers()" :key="teacher.userID" class="class-info-block">
					<div><strong>{{ teacher.roleName }}:</strong> {{ teacher.name }} ({{ teacher.userID }})</div>
				</div>
			</div>

		</label>

		<div class="flex-row flex-justify-between flex-align-stretch mb-1">
			<fieldset style="flex-grow:1">
				<legend>Product</legend>
				<label class='form-entry-block'>
					<div>Base Product:</div>
					<select v-if="baseProducts.objects.length > 0" v-model='newLicense.sku'>
						<option value="" disabled>SELECT A PRODUCT</option>
						<option v-for='baseProduct in baseProducts' :key='baseProduct.sku' :value='baseProduct.sku'>{{baseProduct.sku}} &nbsp; &nbsp; &mdash; &nbsp; &nbsp; "{{ baseProduct.name }}"</option>
					</select>
					<div v-else>Product List Loading...</div>
				</label>


				<label class='form-entry-block' v-if="newLicense.sku">
					<div>Course Name:</div>
					<select v-model="newLicense.courseID">
						<option value="" disabled>SELECT A COURSE</option>
						<option v-for="course in courses" :key='course.courseID' :value="course.courseID">{{course.courseName}}</option>
					</select>
				</label>


				<label class='form-entry-block' v-if="newLicense.sku">
					<div>Upgrades:</div>

					<select v-if='possibleUpgrades.length' @change='addUpgradeToLicense'>
						<option value="" disabled selected>SELECT AN UPGRADE</option>
						<option v-for='upgrade in possibleUpgrades' :key='upgrade.sku' :value='upgrade.sku'>{{ upgrade.name }}</option>
					</select>

					<p v-else-if='!possibleUpgrades.length'>Please select a base product</p>
					<p v-else>No upgrades available for this product.</p>

					<div class='flex'>
						<UpgradeDisplay v-for='upgrade in newLicense.upgradeSKUs' :key='upgrade'
							:sku='upgrade'
							@remove='removeUpgradeSKU(upgrade)'
						/>
					</div>
				</label>


				<label class='form-entry-block' v-if="newLicense.sku && selectedBaseProduct">
					<div>Payment Option</div>
					<select v-model="newLicense.pricingOptionID">
						<option value="" disabled>SELECT A Pricing Option</option>
						<option v-for="pricingOption in selectedBaseProduct.pricingOptions" :key='pricingOption.id' :value="pricingOption.id">{{pricingOption.name}} - {{pricingOption.priceString}}</option>
					</select>
				</label>
			</fieldset>

			<fieldset style="flex-grow:1;">
				<legend>Associated Orders</legend>
				<div v-if="newLicense.orderID">
					<div class="bold">Currently-associated order / contract</div>
					<div>Order ID: {{newLicense.orderID}}</div>
					<div>LineItem #{{newLicense.lineItemIndex+1 || 'N/A'}}</div>
				</div>
				
				<div class="bold mt-1">Originating Order(s):</div>
				<div v-for="orderID in newLicense.originOrderIDs" :key="orderID">{{ orderID }}</div>
				<div class="bold mt-1">Renewal Order(s):</div>
				<div v-for="orderID in newLicense.renewOrderIDs" :key="orderID">{{ orderID }}</div>

			</fieldset>
		</div>



		<button class="button" @click="showCourseVariations = true">Course Variations</button>&nbsp;
		<button class="button" @click="save" :disabled="!changesMade">Save Changes</button>&nbsp;
		<button class="button" @click="saveClose" :disabled="!changesMade">Save &amp; Close</button>
		&nbsp;&nbsp;&nbsp;
		<input id="SendLicenseEmail" type="checkbox" v-model="sendEmail" />
		<label for="SendLicenseEmail">(Re-)Send New License Email</label>


		<!-- Check Tracking -->
		<div style="max-width: min( 1200px, 90% ); margin: 8em auto 0 auto;">
			<h2 class="flex-row flex-align-center flex-gap-05 mb-05">
				Validate tracking for this license
				<button class="pillButton secondary blue bg" @click.stop="verifyTracking()">Check Tracking<span v-if="busyTracking">&nbsp;&nbsp;<span class="icon-loop2 spin1" /></span></button>
			</h2>

			<!-- Tracking Report -->
			<div v-if="trackingReport" class="mb-3">
				<TrackingValidationReport v-for="line in trackingReport" :line="line" class="mt-1" :key="line.trackingID" />
			</div>
		</div>


		<!-- Re-generate Available Media Index -->
		<div style="max-width: min( 1200px, 90% ); margin: 1em auto 8em auto;">
			<h2 class="flex-row flex-align-center flex-gap-05 mb-05">
				Re-Initialize "Available Media" Index for this license
				<button class="pillButton secondary blue bg" @click.stop="initializeMediaIndex()">Init Media Index<span v-if="busyMediaIndex">&nbsp;&nbsp;<span class="icon-loop2 spin1" /></span></button>
			</h2>

			<!-- Indexing Report -->
			<div v-if="mediaIndexReport">
				<div v-for="line in mediaIndexReport" :key="line.userKey">{{ line.userKey }}: {{ line.line }}</div>
			</div>
		</div>


	</section>
	<StudentStatisticsWrapper v-else-if="mode == 'stats'" :mode="statsMode" :userID="License.primaryUserID" :license="newLicense" class="mx-1 mb-1" />
	

	<StretchModal :showModal="showCourseVariations" :clickToClose="false" @close="showCourseVariations = false">
		<template #header>Active Variations</template>
		<div class="container pa-1">
			<VariationSelector
				v-if="License"
				:Course="allCourses.findByAttribute('courseID', License.courseID)"
				:License="License" />
		</div>
	</StretchModal>

	<UserDetailsModal :user="showUser" ref="userDetails" width="75%" height="85%" />
</div>
</template>

<script>
import LicenseReportSettings from './LicenseReportSettings.vue'
import StudentStatisticsWrapper from '@/features/StudentStatistics/StudentStatisticsWrapper.vue'
import VariationSelector from '@/features/Courses/VariationSelector'
import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'
import CustomerSearchDropdown from '@/features/SalesManagement/Customers/CustomerSearchDropdown.vue'
import AffiliateSearchDropdown from '@/features/SalesManagement/Affiliates/AffiliateSearchDropdown.vue'

import CourseAPI from '@/api/CourseAPI.js'
import LicenseAPI from '@/api/LicenseAPI.js'
import ProductsAPI from '@/api/ProductsAPI.js'
import SalesManagementAPI from '@/api/SalesManagementAPI.js'
import SystemToolsAPI from '@/api/SystemToolsAPI.js'
import UserAPI from '@/api/UserAPI.js'

import Affiliate from '@/features/SalesManagement/Affiliates/Affiliate.js'
import Customer from '@/features/SalesManagement/Customers/Customer.js'
import License from '@/models/License.js'
import OrderedSet from '@/libraries/OrderedSet.js'

import moment from 'moment'
import UpgradeDisplay from './UpgradeDisplay.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import TrackingValidationReport from '@/components/TrackingValidationReport.vue'

export default {
	name: 'LicenseDetails',
	
	props: {
		License: Object,
		Mode: {
			type: String,
      default: 'edit'
		},

		tab: {
			type: String,
			default: 'general'
		},
	},

	data() {
		return {
			mode: 'general',
			statsMode: 'overview',

			newLicense: new License(),
			customer: null,
			affiliate: null,
			master: null,
			student: null,
			sendEmail: false,
			showSpinner: false,

			showUser: null,

			reportsInitialized: false,
			showCourseVariations: false,
			showPushReportSettings: false,

			trackingReport: null,
			busyTracking: false,

      busyMediaIndex: false,
      mediaIndexReport: null,

			allCourses: new OrderedSet(),
			products: new OrderedSet(),
		}
	},

	beforeCreate() {
		CourseAPI.getAllCourses().then( data => this.allCourses.objects = data )
		ProductsAPI.getProducts().then( data => this.products.objects = data )
	},

	computed: {
		changesMade() {
			return !this.License.isSame(this.newLicense);
		},

		courses() {
			const possibleCourses = [];
			if (!this.newLicense.sku) return possibleCourses;
			const product = this.products.findByAttribute('sku', this.newLicense.sku);
			if(!product) return possibleCourses;

			for (let courseID of product.courseIDs) {
				possibleCourses.push( this.allCourses.findByAttribute('courseID', courseID) );
			}

			return possibleCourses;
		},

		displayMaster() { return this.master ? `${this.master.fullName}     [ ${this.master.emailAddress}  |  user # ${this.master.userID} ]` : ''; },
		displayStudent() { return this.student ? `${this.student.fullName}     [ ${this.student.emailAddress}  |  user # ${this.student.userID} ]` : ''; },


		baseProducts() {
			return this.products
		},

		selectedBaseProduct() {
			if( this.baseProducts.objects.length == 0 ) return null
			if(!this.newLicense || !this.newLicense.sku) return null
			const prod = this.baseProducts.find(elem => elem.sku == this.newLicense.sku) || null
			return prod
		},

		possibleUpgrades() {
			const possibleUpgrades = []
			if (!this.newLicense.sku) return possibleUpgrades

			for (let product of this.products) {
				if (product.upgradableFrom.includes(this.newLicense.sku)) {
					possibleUpgrades.push(product)
				}
			}

			return possibleUpgrades
		},
	},

	watch: {
		License() { this.syncLicenses(); },
		customer( customerObj ) {
			if(!customerObj) this.newLicense.customerID = null;
			else this.newLicense.customerID = customerObj.id;
		},
		affiliate( affiliateObj ) {
			if(!affiliateObj) this.newLicense.affiliateID = null;
			else this.newLicense.affiliateID = affiliateObj.id;
		},
	},

	async created() {
		this.mode = this.tab;
		this.syncLicenses();
		this.getCustomer();
		this.getAffiliate();
		await this.getMaster();
		await this.getStudent();
	},

	methods: {
		async saveClose() {
			await this.save(true)
			this.$emit('close')
		},


		async save(doAlert = true) {
			this.showSpinner = true;
			try {
				this.verifyLicense();
				if (this.Mode === 'new') {
					let res = await LicenseAPI.addLicense(this.newLicense, this.sendEmail);
					this.$emit('success', res);
				} else {
					let res = await LicenseAPI.editLicense(this.newLicense, this.sendEmail);
					this.$emit('success', res);
				}


				// if(doAlert) alert(`License ${this.newLicense.serialKey} successfully ${ this.Mode === 'new' ? 'added' : 'modified' }.`)
			} catch (e) {
				console.log(e);
				alert(`Could not save changes: ${e}`);
			}

			try {
				await this.getMaster();
				await this.getStudent();

			} catch(e) {
				if(doAlert) alert(`license was saved, but something went wrong while re-loading users`)
			}
			this.showSpinner = false;
		},

		verifyLicense() {
			let missingProperties = [];
			if (!this.newLicense.courseID) missingProperties.push('courseID');
			if (!this.newLicense.serialKey) missingProperties.push('serialKey');
			if (missingProperties.length)
				throw new Error(`Missing the following required properties:\n${missingProperties.join('\n')}`);
		},

		updateDate(mode, event) {
			const value = event.target.value;
			if (mode === 'exp') {
				this.newLicense.expirationDate = value ? moment(value) : null;
			}
			else if (mode === 'pur') {
				this.newLicense.purchaseDate = value ? moment(value) : null;
			}
		},

		momentToString(m) {
			if (m) {
				return m.format('YYYY-MM-DD');
			}
			return "";
		},

		selectMaster(obj) {
			this.master = obj;
			this.newLicense.masterID = obj ? obj.userID : null;
		},

		selectStudent(obj) {
			this.student = obj;
			this.newLicense.studentID = obj ? obj.userID : null;
		},

		openMaster() {
			if( !this.master ) return
			this.showUser = this.master
			this.$refs.userDetails.open()
		},

		openStudent() {
			if( !this.student ) return
			this.showUser = this.student
			this.$refs.userDetails.open()
		},

		async generateKey() {
			this.newLicense.serialKey = await LicenseAPI.generateKey();
		},

		syncLicenses() {
			Object.assign(this.newLicense, this.License);
			this.newLicense.upgradeSKUs = this.License.upgradeSKUs.slice();
		},

		async resendRegistrationEmail(userID) {
			try {
				await LicenseAPI.sendLicenseRegistrationEmail(userID, this.newLicense.serialKey);
				alert("License registration email queued for sending.");
			} catch (e) {
				alert("Error: license registration email could not be sent.");
			}
		},

		async getCustomer() {
			if(!this.newLicense.customerID) return;
			const res = await SalesManagementAPI.getCustomer(this.newLicense.customerID);
			if(!res) return;
			this.customer = Customer.import(res);
		},

		async getAffiliate() {
			if(!this.newLicense.affiliateID) return;
			const res = await SalesManagementAPI.getAffiliateByID(this.newLicense.affiliateID);
			if(!res) this.affiliate = {id: this.newLicense.affiliateID};
			this.affiliate = Affiliate.import(res);
		},

		async getMaster() {
			if (this.newLicense.masterID) {
				try {
					this.master = await UserAPI.getUser(this.newLicense.masterID);
				} catch(e) {
					if( !this.master ) this.master = {userID: this.newLicense.masterID}
				}
			}
		},

		async getStudent() {
			if (this.newLicense.studentID) {
				try{
					this.student = await UserAPI.getUser(this.newLicense.studentID);
				} catch(e) {
					if( !this.student ) this.student = {userID: this.newLicense.studentID}
				}
			}
		},

		addUpgradeToLicense(event) {
			if (!this.newLicense.upgradeSKUs.includes(event.target.value)) {
				this.newLicense.upgradeSKUs.push(event.target.value);
			}
		},

		removeUpgradeSKU(sku) {
			this.newLicense.upgradeSKUs = this.newLicense.upgradeSKUs.filter(upgrade => upgrade !== sku);
		},

		dropdownDisplayFunction(obj) {
			let display = obj.fullName;
			if (obj.emailAddress) display += ` (${obj.emailAddress})`;
			return display;
		},


		async verifyTracking() {
      this.busyTracking = true
      this.trackingReport = null

      try {
        const res = await SystemToolsAPI.verifyUserTracking( null, this.newLicense.serialKey )
        this.trackingReport = res.data

      } finally {
        this.busyTracking = false
      }
    },


		async initializeMediaIndex() {
			this.busyMediaIndex = true
			this.mediaIndexReport = []

			try {
				var masterRes, studentRes;
				if( this.newLicense.masterID !== null ) masterRes = await SystemToolsAPI.initializeUserMediaIndex( this.newLicense.masterID, this.newLicense.serialKey )
				if( this.newLicense.studentID!== null ) studentRes = await SystemToolsAPI.initializeUserMediaIndex( this.newLicense.studentID, this.newLicense.serialKey )

				if( masterRes ) this.mediaIndexReport = [ ...this.mediaIndexReport, ...masterRes ]
				if( studentRes ) this.mediaIndexReport = [ ...this.mediaIndexReport, ...studentRes ]

			} finally {
				this.busyMediaIndex = false
			}
		}


	},

	components: {
		LicenseReportSettings,
		StudentStatisticsWrapper,
		VariationSelector,
		UserSearchDropdown,
		CustomerSearchDropdown,
		AffiliateSearchDropdown,
		UpgradeDisplay,
		StretchModal,
		UserDetailsModal: () => import('@/features/Users/UserDetailsModal.vue'),
		TrackingValidationReport,
	}
}
</script>



<style scoped>
div.tabLink {
	border-radius: 5px;
	padding: 0.15em 0.5em;
	/* border: 1px solid lightgray; */
	cursor: pointer;
	background: none;
	border: 1px solid rgba(255,255,255, 0);
	color: var(--ekno-blue);
	font-size: 0.9rem;
}
div.tabLink:hover {
	border: 1px solid var(--ekno-blue);
}
div.tabLink.active {
	color: white;
	background: var(--ekno-blue);
	border-color: var(--ekno-blue);
}
</style>
