<template>
<section
	id="uploader"
	class="flex-row flex-align-center flex-justify-center round-05 pa-05"
	:class="{ 'drag' : dragging }"
	@dragover="ev => dragHandler( ev )"
	@dragleave="dragging = false"
	@dragend="dragging = false"
	@drop="ev => dropHandler( ev )"
	ref="uploader"
>
	<div v-if="!uploading">Drag file(s) here to upload</div>
	<div v-else>
		<div v-for="prog in progressObjects" :key="prog.name" >
			{{ prog.name }}: {{ prog.error ? 'ERROR UPLOADING FILE' : prog.pct }}
		</div>
	</div>
</section>
</template>



<script>
import FilesAPI from '@/api/FilesAPI.js'
import FileUploadHelper from './FileUploadHelper.js'

export default {
	name: 'AttachmentFileUploader.vue',


	props: {
		hostType: {
			type: String,
			default: null
		},

		hostID: {
			type: Number,
			default: null
		},
	},


	data() {
		return {
			dragging: false,
			uploading: false,
			progressObjects: []
		}
	},


	methods: {

		dragHandler( event ) {
			event.preventDefault()
			this.dragging = true
		},


		async dropHandler( event ) {
			
			event.preventDefault()
			this.dragging = false
			
			this.progressObjects = []
			this.uploading = true


			const promises = []

			if( !event.dataTransfer.items ) return

			for( const item of [ ...event.dataTransfer.items ] ) {
				if( item.kind !== 'file' ) continue
				const file = item.getAsFile()
				promises.push( this.uploadWorker( file ) )
			}
			
			await Promise.all( promises )
			this.uploading = false

		},


		async uploadWorker( file ) {

			return new Promise( (resolve, reject) => {

				const fileReader = new FileReader()

				fileReader.readAsDataURL(file)
				fileReader.onload = async () => {

					// Show "uploading" text
					const progress = { numChunksComplete: 0, numChunks: 0, pct: '0%', name: file.name, error: false }
					this.progressObjects.push( progress )

					// Create empty file (metadata)
					const { data: fileData } = FileUploadHelper.splitBase64URL( fileReader.result )
					FilesAPI.createEmptyFile( { hostID: this.hostID, hostType: this.hostType, fileName: `affiliate_${this.hostID}_attachment_${file.name}`, mimeType: file.type  } )
					.then( res => {
						const fileID = res.data.id

						// get public URL for image
						FilesAPI.getPublicFilePath( fileID, 'attached_files' )
						.then( urlData => {
							if( !urlData.path ) reject("no file URL")

							// Prepare to upload
							const chunks = FileUploadHelper.chunkBase64String( fileData )
							progress.numChunks = chunks.length
							progress.numChunksComplete = 0

							const chunkPromises = []

							// Upload file chunks
							const uploadChunk = ( idx ) => {
								if( idx >= chunks.length ) {
									resolve()
									this.$emit( 'upload' )
									return
								}
	
								const chunk = chunks[idx]
	
								const p = FilesAPI.uploadFileBase64Chunk( fileID, chunk )
								chunkPromises.push( p )
								p.then( () => {
									progress.numChunksComplete++
									progress.pct = Math.round( progress.numChunksComplete * 100 / progress.numChunks ) + '%'
									uploadChunk( idx + 1 )
								})
								.catch( (e) => {
									console.error( e ? e.message : 'Error while uploading file chunk' )
									progress.error = true
									this.$emit( 'upload' )
									reject()
								})

							}

							uploadChunk( 0 )

						})
					})
				}
			})

		},


	}

}
</script>



<style scoped>
#uploader {
	background-color: var(--pp10-gray-60);
	color: var(--pp10-gray-90);
	min-height: 50px;
}

#uploader.drag {
	background-color: var(--ekno-blue-40);
	color: var(--ekno-blue-80);
}
</style>